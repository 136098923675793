import { useMsal } from "@azure/msal-react";
import { getToken, useLogin } from "../../authConfig";
import { Spinner } from "@fluentui/react";
import { useEffect, useMemo, useRef, useState } from "react";
import { HistoryData, HistoryItem } from "../HistoryItem";
import { Answers, HistoryProviderOptions } from "../HistoryProviders/IProvider";
import { useHistoryManager, HistoryMetaData } from "../HistoryProviders";
import { useTranslation } from "react-i18next";
import styles from "./HistoryPanel.module.scss";
import sideBarIcon from "./../../assets/SideBarIcon.svg";
import { NewChatButton } from "../NewChatButton";

const HISTORY_COUNT_PER_LOAD = 20;

export const HistoryPanel = ({
    provider,
    isOpen,
    notify,
    onClose,
    onChatSelected,
    setIsHistoryPanelOpen,
    onStartNewChat,
}: {
    provider: HistoryProviderOptions;
    isOpen: boolean;
    notify: boolean;
    onClose: () => void;
    onChatSelected: (answers: Answers) => void;
    setIsHistoryPanelOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onStartNewChat: () => void; // Function to start a new chat
}) => {
    const historyManager = useHistoryManager(provider);
    const [history, setHistory] = useState<HistoryMetaData[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [hasMoreHistory, setHasMoreHistory] = useState(false);

    const client = useLogin ? useMsal().instance : undefined;

    // Fetch history when the panel opens or notification updates
    useEffect(() => {
        if (!isOpen) return;
        if (notify) {
            setHistory([]);
            historyManager.resetContinuationToken();
            setHasMoreHistory(true);
        }
    }, [isOpen, notify]);

    const loadMoreHistory = async () => {
        setIsLoading(true);
        const token = client ? await getToken(client) : undefined;
        const items = await historyManager.getNextItems(HISTORY_COUNT_PER_LOAD, token);
        if (items.length === 0) {
            setHasMoreHistory(false);
        }
        setHistory((prevHistory) => [...prevHistory, ...items]);
        setIsLoading(false);
    };

    const handleSelect = async (id: string) => {
        const token = client ? await getToken(client) : undefined;
        const item = await historyManager.getItem(id, token);
        if (item) {
            onChatSelected(item);
        }
    };

    const handleDelete = async (id: string) => {
        const token = client ? await getToken(client) : undefined;
        await historyManager.deleteItem(id, token);
        setHistory((prevHistory) => prevHistory.filter((item) => item.id !== id));
    };

    // Group history items for display
    const groupedHistory = useMemo(() => groupHistory(history), [history]);

    const { t } = useTranslation();

    return (
        <div className={isOpen ? styles.historyPanel : styles.historyPanelHidden}>
            {isOpen && (
                <div className={`${styles.historyContainerWrap}`}>
                    {/* Controls */}
                    <div className={`flex place-content-between h-14 px-3 py-2 ${styles.historyControls}`}>
                        <div className={``}>
                            <button className={`p-2 rounded-lg`} onClick={() => setIsHistoryPanelOpen((prev) => !prev)}>
                                <img src={sideBarIcon} className="sidebar-icon" width={24} height={24} alt="sidebar icon" />
                            </button>
                        </div>
                        {/* New Chat Button */}
                        <NewChatButton className={``} onClick={onStartNewChat} />
                    </div>

                    {/* History List */}
                    <div className={`h-full p-2 overflow-y-auto scrollbar-track-gray-100 ${styles.historyContainer}`}>
                        {Object.entries(groupedHistory).map(([group, items]) => (
                            <div key={group} className={`mt-5 ${styles.group}`}>
                                <p className={`h-9 content-center text-xs font-bold ${styles.groupLabel}`}>{t(group)}</p>
                                {items.map((item) => (
                                    <HistoryItem
                                        key={item.id}
                                        item={item}
                                        onSelect={handleSelect}
                                        onDelete={handleDelete}
                                    />
                                ))}
                            </div>
                        ))}

                        {/* Loading Spinner */}
                        {isLoading && <Spinner style={{ marginTop: "10px" }} />}

                        {/* No History Message */}
                        {history.length === 0 && !isLoading && <p>{t("history.noHistory")}</p>}

                        {/* Infinite Loading Button */}
                        {hasMoreHistory && !isLoading && <InfiniteLoadingButton func={loadMoreHistory} />}
                    </div>
                </div>
            )}
        </div>
    );
};

function groupHistory(history: HistoryData[]) {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    const lastWeek = new Date(today);
    lastWeek.setDate(lastWeek.getDate() - 7);
    const lastMonth = new Date(today);
    lastMonth.setDate(lastMonth.getDate() - 30);

    return history.reduce((groups, item) => {
        const itemDate = new Date(item.timestamp);
        let group;

        if (itemDate >= today) {
            group = "history.today";
        } else if (itemDate >= yesterday) {
            group = "history.yesterday";
        } else if (itemDate >= lastWeek) {
            group = "history.last7days";
        } else if (itemDate >= lastMonth) {
            group = "history.last30days";
        } else {
            group = itemDate.toLocaleDateString(undefined, { year: "numeric", month: "long" });
        }

        if (!groups[group]) {
            groups[group] = [];
        }
        groups[group].push(item);
        return groups;
    }, {} as Record<string, HistoryData[]>);
}

const InfiniteLoadingButton = ({ func }: { func: () => void }) => {
    const buttonRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        func();
                    }
                });
            },
            { root: null, threshold: 0 }
        );

        if (buttonRef.current) {
            observer.observe(buttonRef.current);
        }

        return () => {
            if (buttonRef.current) {
                observer.unobserve(buttonRef.current);
            }
        };
    }, [func]);

    return <button ref={buttonRef} onClick={func} />;
};
