import { DefaultButton } from "@fluentui/react";
import { useMsal } from "@azure/msal-react";
import { useTranslation } from "react-i18next";

import styles from "./LoginButton.module.scss";
import { getRedirectUri, loginRequest, appServicesLogout, getUsername, checkLoggedIn } from "../../authConfig";
import { useState, useEffect, useContext } from "react";
import { LoginContext } from "../../loginContext";

import SignOutIcon from "../../assets/SignOutIcon.svg"

export const LoginButton = ({ onLoginSuccess }: { onLoginSuccess: () => void }) => {
    const { instance } = useMsal();
    const { loggedIn, setLoggedIn } = useContext(LoginContext);
    const activeAccount = instance.getActiveAccount();
    const [username, setUsername] = useState<string>("");
    const { t } = useTranslation();
    const firstname = username.split(".")[0];

    // Fetch username if logged in
    useEffect(() => {
        const fetchUsername = async () => {
            if (loggedIn) {
                const name = await getUsername(instance);
                setUsername(name ?? "");
            }
        };
        fetchUsername();
    }, [loggedIn, instance]);
    
    const handleLoginPopup = async () => {
        /**
         * When using popup and silent APIs, we recommend setting the redirectUri to a blank page or a page
         * that does not implement MSAL. Keep in mind that all redirect routes must be registered with the application
         * For more information, please follow this link: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/login-user.md#redirecturi-considerations
         */
        try {
            await instance.loginPopup({
                ...loginRequest,
                redirectUri: getRedirectUri(),
            });

            const isLoggedIn = await checkLoggedIn(instance);
            setLoggedIn(isLoggedIn);

            if (isLoggedIn) {
                const name = await getUsername(instance);
                setUsername(name ?? "");

                // Notify parent about successful login
                onLoginSuccess();
            }
        } catch (error) {
            console.error("Login failed", error);
        }
    };

    // Handle logout using popup
    const handleLogoutPopup = async () => {
        if (activeAccount) {
            try {
                await instance.logoutPopup({
                    mainWindowRedirectUri: "/", // Redirects after logout
                    account: activeAccount,
                });

                const isLoggedIn = await checkLoggedIn(instance);
                setLoggedIn(isLoggedIn);

                if (!isLoggedIn) {
                    setUsername("");
                }
            } catch (error) {
                console.error("Logout failed", error);
            }
        } else {
            appServicesLogout();
            setLoggedIn(false);
            setUsername("");
        }
    };

    return (
        <DefaultButton
            text={loggedIn ? `${t("Log out")}` : `${t("Begin")}`}
            className={`${!loggedIn ? styles.mainLoginButton : styles.loginButton}`}
            onClick={loggedIn ? handleLogoutPopup : handleLoginPopup}
        >
            {loggedIn && <img src={SignOutIcon} className="signout" alt="sign out logo"/>}
        </DefaultButton>
    );
};
