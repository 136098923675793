import React, { useState, useEffect, useRef, RefObject } from "react";
import { Outlet, NavLink, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./Layout.module.scss";

import { useLogin } from "../../authConfig";

import { LoginButton } from "../../components/LoginButton";
import WriteFeedback from "../../assets/WriteFeedback.png";

import { IconButton } from "@fluentui/react";

const Layout = () => {
    const { t } = useTranslation();
    const [menuOpen, setMenuOpen] = useState(false);
    const menuRef: RefObject<HTMLDivElement> = useRef(null);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
            setMenuOpen(false);
        }
    };

    useEffect(() => {
        if (menuOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [menuOpen]);

    return (
        <div className={styles.layout}>
            <Outlet />
            <div className={styles.feedbBackContainer}>
                <a title="Give Feedback" href="https://forms.office.com/r/zH66dEbjp6" target="_blank"><img src={WriteFeedback} alt="Give Feedback" /></a>
            </div>
            <div className={styles.infoMessageContainer}>
                <p>{t("infoMessage")}</p>
            </div>
        </div>
    );
};

export default Layout;
