import { Button } from "@fluentui/react-components";
import NewChatIcon from "./../../assets/NewChat.svg";
import styles from "./NewChatButton.module.scss";

interface Props {
  className?: string; // Additional class for styling
  onClick: () => void; // Click handler
  disabled?: boolean; // Button disabled state
}

export const NewChatButton = ({ className, onClick, disabled = false }: Props) => {
  return (
    <div className={`${className ?? ""}`}>
      <Button className={`${styles.new_Chat_Button}`} disabled={disabled} onClick={onClick}>
        <img src={NewChatIcon} className="newChat-icon" width={24} height={24} alt="New Chat icon" />
      </Button>
    </div>
  );
};
