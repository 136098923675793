import React, { useState, useRef, useContext, useEffect } from "react";
import { Callout } from "@fluentui/react";
import styles from "./LoginModal.module.scss";
import { LoginButton } from "../../components/LoginButton";
import { LoginContext } from "../../loginContext";
import { getUsername } from "../../authConfig";
import { useMsal } from "@azure/msal-react";


const LoginModal = () => {
  const [isCalloutVisible, setIsCalloutVisible] = useState(false);
  const [username, setUsername] = useState<string>("");
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { loggedIn } = useContext(LoginContext);
  const { instance } = useMsal(); // Get MSAL client instance

  // Fetch and update username when logged in
  useEffect(() => {
    const fetchUsername = async () => {
      if (loggedIn) {
        const name = await getUsername(instance); // Pass the MSAL client instance
        setUsername(name ?? "");
      }
    };
    fetchUsername();
  }, [loggedIn, instance]);

  // Extract initials
  const getInitials = (name: string) => {
    const parts = name.split(".");
    if (parts.length >= 2) {
      return `${parts[0][0]}${parts[1][0]}`.toUpperCase();
    }
    return name[0]?.toUpperCase() || "";
  };

  const handleLoginSuccess = () => {
    console.log("Login successful!");
    setIsCalloutVisible(false);
  };

  return (
    <div className="relative">
      {/* Trigger Button */}
      <button
        className={`text-xs ${styles.profileButton}`}
        ref={buttonRef}
        onClick={() => setIsCalloutVisible(!isCalloutVisible)}
      >
        {loggedIn ? getInitials(username) : "UN"}
      </button>

      {/* LoginModal Callout */}
      {isCalloutVisible && (
        <Callout
          role="dialog"
          gapSpace={-9}
          target={buttonRef}
          onDismiss={() => setIsCalloutVisible(false)}
          setInitialFocus
          styles={{
            root: { boxShadow: "0 4px 8px rgba(0,0,0,0.2)", borderRadius: "20px", marginRight:"-10px" },
            calloutMain: { padding: "8px 20px", borderRadius: "20px"},
            beak: {
              display: "none", // Explicitly hide the beak
            },
            beakCurtain: {
              display: "none", // Explicitly hide the beak curtain
            },
          }}
        >
          {/* Login Form */}
          <div className={`${styles.profileModal}`}>
            <div className={`py-3 ${styles.profileModalUser}`}>
              <span>{username}</span>
            </div>
            <hr></hr>
            <div className={`py-3 ${styles.profileModalLogout}`}>
              <LoginButton onLoginSuccess={handleLoginSuccess} />
            </div>
          </div>
        </Callout>
      )}
    </div>
  );
};

export default LoginModal;
