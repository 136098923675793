import React, { useState, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import styles from "./HistoryItem.module.scss";
import { DefaultButton } from "@fluentui/react";
import { Callout } from "@fluentui/react";
import OptionDotsIcon from "../../assets/OptionDotsIcon.svg";
import DeteleIcon from "../../assets/DeleteIcon.svg";

export interface HistoryData {
    id: string;
    title: string;
    timestamp: number;
}

interface HistoryItemProps {
    item: HistoryData;
    onSelect: (id: string) => void;
    onDelete: (id: string) => void;
}

// Utility function to strip HTML tags
const stripHtmlTags = (str: string) => {
    return str.replace(/<\/?[^>]+(>|$)/g, "");
};

export function HistoryItem({ item, onSelect, onDelete }: HistoryItemProps) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isCalloutVisible, setIsCalloutVisible] = useState(false);
    const buttonRef = useRef<HTMLButtonElement>(null);

    const handleDelete = useCallback(() => {
        setIsModalOpen(false);
        onDelete(item.id);
    }, [item.id, onDelete]);

    return (
        <div className={`flex items-center justify-between p-1 rounded-md transition-colors duration-200 hover:bg-gray-100 h-9 ${styles.historyItem} ${isCalloutVisible ? styles.historyItemActive : ''}`}>
            <button onClick={() => onSelect(item.id)} className={`flex-grow text-left p-0 mr-1 bg-none border-none cursor-pointer truncate max-w-52 ${styles.historyItemButton}`}>
                <div className={`whitespace-nowrap text-sm ${styles.historyItemTitle}`}>
                    {stripHtmlTags(item.title)}
                </div>
            </button>
            <button className={`opacity-0 transition-opacity duration-200 bg-none border-none cursor-pointer p-1 rounded-full text-gray-500 hidden group-hover:block focus:opacity-100 focus:block ${styles.historyItemOptions}`} ref={buttonRef} onClick={() => setIsCalloutVisible(!isCalloutVisible)}>
                <img src={OptionDotsIcon} className="OptionDots-icon" alt="OptionDots icon" />
            </button>

            {isCalloutVisible && (
                <Callout
                    role="dialog"
                    gapSpace={-15}
                    target={buttonRef}
                    onDismiss={() => setIsCalloutVisible(false)}
                    setInitialFocus
                    styles={{
                        root: { boxShadow: "0 4px 8px rgba(0,0,0,0.2)", borderRadius: "15px", marginLeft: "10px" },
                        calloutMain: { borderRadius: "15px", padding: "8px" },
                        beak: { display: "none" },
                        beakCurtain: { display: "none" },
                        container: { borderRadius: "15px" },
                    }}
                >
                    <div className={`${styles.historyItemOptionsModal}`}>
                        <button onClick={() => { setIsModalOpen(true); setIsCalloutVisible(!isCalloutVisible); }} className={`flex p-3 rounded-lg ${styles.historyItemDelete}`} aria-label="delete this chat history">
                            <img src={DeteleIcon} className={`pr-3`} alt="delete icon" />
                            <span className={`text-base`}>Delete</span>
                        </button>
                    </div>
                </Callout>
            )}
            <DeleteHistoryModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} onConfirm={handleDelete} />
        </div>
    );
}

function DeleteHistoryModal({ isOpen, onClose, onConfirm }: { isOpen: boolean; onClose: () => void; onConfirm: () => void }) {
    if (!isOpen) return null;
    const { t } = useTranslation();
    return (
        <div className={styles.modalOverlay}>
            <div className={styles.modalContent}>
                <h2 className={styles.modalTitle}>{t("history.deleteModalTitle")}</h2>
                <p className={styles.modalDescription}>{t("history.deleteModalDescription")}</p>
                <div className={styles.modalActions}>
                    <DefaultButton onClick={onClose} className={styles.modalCancelButton}>
                        {t("history.cancelLabel")}
                    </DefaultButton>
                    <DefaultButton onClick={onConfirm} className={styles.modalConfirmButton}>
                        {t("history.deleteLabel")}
                    </DefaultButton>
                </div>
            </div>
        </div>
    );
}
